@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/HelveticaNeue/helveticaneuecyr-roman.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/HelveticaNeue/helveticaneuecyr-medium1.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: 'HelveticaNeue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}